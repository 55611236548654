export const ROUTE_NAMES = {
  COUNTRY_REPORT_CARD: "Country Report Card",
};

export const DATE_FIELDS = {
  DATE_RECEIVED: "Date Received",
  SHIP_DATE: "Shipping Date",
  TREATMENT_DATE: "Treatment Date",
  QUARTER_DATE: "Quarter Date",
  CREATION_DATE: "Order Creation Date"
};
