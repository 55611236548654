import axios from "axios";
import store from "../store/index";
import router from "../router";

const instance = axios.create();

instance.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

instance.interceptors.request.use(
  (config) => {
    // store.getters["auth/getloggedInUserFullName"];
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use((response) => {
//     return response;
// }, (error) => {
//     if ((error && error.response && error.response.status == 401)) {
//         store.dispatch("auth/reset");
//         router.push({ path: "/login", query: { returnUrl: router.currentRoute.path } });
//     }
//     return Promise.reject(error);
// });

let refreshing_token = null;

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalConfig = error.config;
    if (
      originalConfig !== undefined &&
      originalConfig.url !== "/login" &&
      error.response
    ) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
          try {
            //Handle multiple refresh call
            let refreshToken = localStorage.getItem("refreshToken");
            refreshing_token = refreshing_token
              ? refreshing_token
              : store.dispatch("auth/tryRefreshToken", {
                  refreshToken: refreshToken,
                });
            //Refresh JWT token with refresh token
            await refreshing_token;
            refreshing_token = null;

            // Resend original call to be executed again
            return instance(originalConfig);
          } catch (_error) {
            // Logout user since cannot refresh token
            if (router.currentRoute.path !== "/login") {
              let payload = { returnUrl: router.currentRoute.path };
              store.dispatch("auth/logout", payload);
            }
            return Promise.reject(_error);
          }
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
