import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  filters: {},
  datas: [],
  year: 0,
  isLoadingCount: 0,
};
export const mutations = {
  setData(state, data) {
    state.datas = data;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setYear(state, year) {
    state.year = year;
  },
};
export const actions = {
  loadDatas({ commit, state }) {
    return new Promise((resolve, reject) => {
      state.isLoadingCount++;
      axios
        .post(
          `/CountryDashboard`,
          {
            year: state.year,
            manufacturers: state.filters.Manufacturers,
          },
          {
            signal: store.getters.abortSignal,
          }
        )
        .then((res) => {
          state.isLoadingCount--;

          commit("setData", res.data);
          resolve();
        })
        .catch((e) => {
          state.isLoadingCount--;

          if (!e.message == "canceled") reject(e);
        });
    });
  },
};
export const getters = {
  datas: (state) => state.datas,
  isLoading: (state) => state.isLoadingCount > 0,
};
