<template>
  <v-dialog v-model="isActive" :max-width="width">
    <v-card-title class="pa-0 secondary--text">
      <v-toolbar color="primary" elevation="0" class="white--text">
        <v-toolbar-title>{{ $options.propsData.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="white--text" icon @click="close">
          <v-icon size="25px" class="white--text" color="white">
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <dialog-child
      v-bind="$options.propsData"
      @close="close"
      ref="dialog"
      @on-save="handleOnSave"
    />
  </v-dialog>
</template>

<script>
export default {
  props: {
    onSaveHandler: {
      type: Function,
    },
  },
  methods: {
    handleOnSave() {
      this.$emit("on-save");
      this.onSaveHandler();
    },
  },
};
</script>
