import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store/";
import "./plugins";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import momentTimezone from "moment-timezone";
import moment from "moment";
import VueGoogleCharts from "vue-google-charts/legacy";
import i18n from "./i18n";

import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});
import DialogLayout from "./components/dialogs/DialogLayout";
Vue.prototype.$dialog.layout("default", DialogLayout);
Vue.config.productionTip = process.env.NODE_ENV == "development";
Vue.use(require("vue-moment"), {
  momentTimezone,
});

Vue.prototype.$moment.updateLocale(moment.locale(), {
  invalidDate: "TBD"
});

Vue.mixin({
  methods: {
    graphSize(isPrinting) {
      if (isPrinting) return 300;
      else return 400;
    },
  },
});
Vue.config.performance = true;
Vue.use(CoreuiVue);
//Vue.use(axios);
Vue.use(VueGoogleCharts);

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  vuetify,
  i18n,

  components: {
    App,
  },
});
