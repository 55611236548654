import axios from "@/plugins/axios";
import { ActionContext } from "vuex";
import store from "../../store/index";

import router from "../../router/index";

const initialState = () => ({
  idToken: null,
  loggedInUserId: null,
  loggedInUserRole: null,
  loggedInUserRoleID: 1,
  loggedInUserFirstName: null,
  loggedInUserLastName: null,
  loggedInUserFullName: null,
  loggedInUserOrgId: null,
  loggedInUserCountryId: null,
  loggedInUserIsDisplayByProductType: null,
  loggedInUserIsDisplayByProductBrand: null,
  loginRes: null,
  forgotPasswordResult: null,
  resetPasswordResult: null,
});

export const state = initialState();

export const mutations = {
  RESET(state) {
    state = initialState();
  },
  SET_AUTH_USER(state, userData) {
    state.idToken = userData.token;
    state.loggedInUserId = userData.userId;
    state.loggedInUserRole = "";
    state.loggedInUserRoleID = userData.roleId;
    state.loggedInUserFirstName = userData.firstname;
    state.loggedInUserLastName = userData.lastname;
    state.loggedInUserFullName =
      state.loggedInUserFirstName + " " + state.loggedInUserLastName;
    state.loggedInUserOrgId = userData.orgId;
    state.loggedInUserCountryId = userData.countryId;
    state.loggedInUserIsDisplayByProductType = userData.isDisplayByProductType;
    state.loggedInUserIsDisplayByProductBrand = userData.isDisplayByProductBrand;
    state.loginRes = userData.loginRes;
  },
  AUTOSET_AUTH_USER(state, userData) {
    state.idToken = userData.token;
    state.loggedInUserId = userData.userId;
    state.loggedInUserRole = "";
    state.loggedInUserRoleID = userData.roleId;
    state.loggedInUserFirstName = userData.firstname;
    state.loggedInUserLastName = userData.lastname;
    state.loggedInUserFullName =
      state.loggedInUserFirstName + " " + state.loggedInUserLastName;
    state.loggedInUserOrgId = userData.orgId;
    state.loggedInUserCountryId = userData.countryId;
    state.loggedInUserIsDisplayByProductType = userData.isDisplayByProductType;
    state.loggedInUserIsDisplayByProductBrand = userData.isDisplayByProductBrand;
  },
  CLEAR_AUTH_DATA(state) {
    state.idToken = null;
    state.loggedInUserId = null;
    state.loggedInUserFirstName = null;
    state.loggedInUserLastName = null;
    state.loggedInUserFullName = null;
    state.loggedInUserRole = null;
    state.loggedInUserRoleID = null;
    state.loggedInUserOrgId = null;
    state.loggedInUserCountryId = null;
    state.loggedInUserIsDisplayByProductType = null;
    state.loggedInUserIsDisplayByProductBrand = null;
  },
  SET_FORGOT_PASSWORD_RESULT(state, userData) {
    state.forgotPasswordResult = userData.forgotPasswordResult;
  },
  SET_RESET_PASSWORD_RESULT(state, payload) {
    state.resetPasswordResult = payload;
  },
  setLoggedInUserIsDisplayByProductType(state, value) {
    localStorage.setItem("userIsDisplayByProductType", value);
    state.loggedInUserIsDisplayByProductType = value;
  },
  setLoggedInUserIsDisplayByProductBrand(state, value) {
    localStorage.setItem("userIsDisplayByProductBrand", value);
    state.loggedInUserIsDisplayByProductBrand = value;
  },
};

export const actions = {
  reset({ commit }) {
    commit("RESET");
  },
  login(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/User/Authenticate", payload)
        .then((res) => {
          const now = new Date();
          // 30min * 60 seconds * 1000 milliseconds
          const expirationDate = now.getTime() + 3600000;
          const jwt = JSON.parse(window.atob(res.data.jwtToken));
          const userId = jwt["id"];
          localStorage.setItem("token", res.data.jwtToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          localStorage.setItem("userId", userId);
          localStorage.setItem("userFirstName", res.data.firstName);
          localStorage.setItem("userLastName", res.data.lastName);
          localStorage.setItem("userOrgId", res.data.dimensionManufacturerId);
          localStorage.setItem("userCountryId", res.data.countryId);
          localStorage.setItem("userRole", "ADM");
          localStorage.setItem("roleId", res.data.rolId);
          //localStorage.setItem("userRole", res.data.role?.name_en);
          localStorage.setItem("expirationDate", expirationDate.toString()); //should probably validation with token backend
          localStorage.setItem("userIsDisplayByProductType", res.data.isDisplayByProductType);
          localStorage.setItem("userIsDisplayByProductBrand", res.data.isDisplayByProductBrand);

          context.commit("SET_AUTH_USER", {
            token: res.data.jwtToken,
            refreshToken: res.data.refreshToken,
            firstname: res.data.firstName,
            lastname: res.data.lastName,
            userId,
            orgId: res.data.dimensionManufacturerId,
            countryId: res.data.countryId,
            role: res.data.role?.name_en,
            roleId: res.data.roleId,
            loginRes: res,
            isDisplayByProductType: res.data.isDisplayByProductType,
            isDisplayByProductBrand: res.data.isDisplayByProductBrand
          });
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setUser(context, payload) {
    const now = new Date();
    // 30min * 60 seconds * 1000 milliseconds
    const expirationDate = now.getTime() + 3600000;
    const tokenBody = context.getters["tokenBody"](payload.jwtToken);
    const jwt = JSON.parse(window.atob(tokenBody));
    const userId = jwt["id"];
    localStorage.setItem("token", payload.jwtToken);
    localStorage.setItem("refreshToken", payload.refreshToken);
    localStorage.setItem("userId", userId);
    localStorage.setItem("userFirstName", payload.firstName);
    localStorage.setItem("userLastName", payload.lastName);
    localStorage.setItem("userOrgId", payload.dimensionManufacturerId);
    localStorage.setItem("userCountryId", payload.countryId);
    localStorage.setItem("userRole", payload.userRole);
    localStorage.setItem("userRoleId", payload.roleId);
    //localStorage.setItem("userRole", res.data.role?.name_en);
    localStorage.setItem("expirationDate", expirationDate.toString()); //should probably validation with token backend
    localStorage.setItem("userIsDisplayByProductType", payload.isDisplayByProductType);
    localStorage.setItem("userIsDisplayByProductBrand", payload.isDisplayByProductBrand);

    context.commit("SET_AUTH_USER", {
      token: payload.jwtToken,
      refreshToken: payload.refreshToken,
      firstname: payload.firstName,
      lastname: payload.lastName,
      userId,
      orgId: payload.dimensionManufacturerId,
      countryId: payload.countryId,
      role: payload.role?.name_en,
      roleId: payload.roleId,
      loginRes: payload,
      isDisplayByProductType: payload.isDisplayByProductType,
      isDisplayByProductBrand: payload.isDisplayByProductBrand
    });
  },
  tryRefreshToken(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/User/RefreshToken", payload).then((res) => {
        const now = new Date();
        // 30min * 60 seconds * 1000 milliseconds
        const expirationDate = now.getTime() + 3600000;
        // const jwt = JSON.parse(window.atob(res.data.jwtToken));
        // const userId = jwt["id"];
        const userId = localStorage.getItem("userId");
        localStorage.setItem("token", res.data.jwtToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        localStorage.setItem("userId", userId);
        localStorage.setItem("userFirstName", res.data.firstName);
        localStorage.setItem("userLastName", res.data.lastName);
        localStorage.setItem("userOrgId", res.data.dimensionManufacturerId);
        localStorage.setItem("userCountryId", res.data.countryId);
        localStorage.setItem("userRole", res.data.role?.name_en);
        localStorage.setItem("RoleId", res.data.roleId);
        localStorage.setItem("expirationDate", expirationDate.toString()); //should probably validation with token backend
        localStorage.setItem("userIsDisplayByProductType", res.data.isDisplayByProductType);
        localStorage.setItem("userIsDisplayByProductBrand", res.data.isDisplayByProductBrand);

        context.commit("SET_AUTH_USER", {
          token: res.data.jwtToken,
          refreshToken: res.data.refreshToken,
          firstname: res.data.firstName,
          lastname: res.data.lastName,
          userId,
          orgId: res.data.dimensionManufacturerId,
          countryId: res.data.countryId,
          role: res.data.role?.name_en,
          roleId: res.data.roleId,
          loginRes: res,
          isDisplayByProductType: res.data.isDisplayByProductType,
          isDisplayByProductBrand: res.data.isDisplayByProductBrand
        });
        // context.dispatch("setLogoutTimer", expirationDate);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
    })
  },
  tryAutoLogin({ commit }) {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    if (!token) {
      return;
    }
    const expirationDate = new Date(
      parseInt(localStorage.getItem("expirationDate")) //should probably validation with token backend
    );
    const now = new Date();
    if (now >= expirationDate) {
      return;
    }
    const userId = localStorage.getItem("userId");
    const userFirstName = localStorage.getItem("userFirstName");
    const userLastName = localStorage.getItem("userLastName");
    const userOrgId = localStorage.getItem("userOrgId");
    const userCountryId = localStorage.getItem("userCountryId");
    const userRoleId = localStorage.getItem("userRoleId");
    const userRole = localStorage.getItem("userRole");
    const userIsDisplayByProductType = localStorage.getItem("userIsDisplayByProductType");
    const userIsDisplayByProductBrand = localStorage.getItem("userIsDisplayByProductBrand");

    commit("AUTOSET_AUTH_USER", {
      token: token,
      refreshToken: refreshToken,
      userId: userId,
      firstname: userFirstName,
      lastname: userLastName,
      orgId: userOrgId,
      countryId: userCountryId,
      roleId: userRoleId,
      role: userRole,
      isDisplayByProductType: userIsDisplayByProductType == 'true' ? true : false,
      isDisplayByProductBrand: userIsDisplayByProductBrand == 'true' ? true : false,
    });
  },
  logout({ commit }, payload) {
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userFirstName");
    localStorage.removeItem("userLastName");
    localStorage.removeItem("userOrgId");
    localStorage.removeItem("userCountryId");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userIsDisplayByProductType");
    localStorage.removeItem("userIsDisplayByProductBrand");
    commit("CLEAR_AUTH_DATA");
    //router.push({name: 'Login'});
    router.push({ path: "/login", query: { returnUrl: payload?.returnUrl } });
  },
  resetPassword(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/User/ResetPassword", payload)
        .then((res) => {
          context.commit("SET_RESET_PASSWORD_RESULT", res);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
};

export const getters = {
  tokenBody: () => (token) => {
    return splittedToken(token, 1);
  },
  getloggedInUserFullName: (state) => state.loggedInUserFullName,
  getloggedInUserInitials: (state) => {
    let initials = "";
    if (!!state.loggedInUserFirstName)
      initials += state.loggedInUserFirstName.charAt(0).toUpperCase();
    if (!!state.loggedInUserLastName)
      initials += state.loggedInUserLastName.charAt(0).toUpperCase();

    return initials;
  },
  getLoggedInUserOrgId: (state) => state.loggedInUserOrgId,
  getLoggedInUserCountryId: (state) => state.loggedInUserCountryId,
  getLoggedInUserId: (state) => state.loggedInUserId,
  getIsAuthenticated: (state) => state.idToken !== null,
  getLoggedInUserRole: (state) => state.loggedInUserRole,
  getLoggedInUserRoleId: (state) => state.loggedInUserRoleID,
  getLoginResult: (state) => state.loginRes,
  getForgotPasswordResult: (state) => state.forgotPasswordResult,
  getResetPasswordResult: (state) => state.resetPasswordResult,
  getLoggedInUserIsDisplayByProductType: (state) => state.loggedInUserIsDisplayByProductType,
  getLoggedInUserIsDisplayByProductBrand: (state) => state.loggedInUserIsDisplayByProductBrand,
};

const splittedToken = (token, index) => {
  if (token == null || token.trim() == "") return null;
  const splitted = token.split(".");

  if (splitted.length < 3) return null;
  else if (index != null) return splitted[index];
  else return splitted;
};
