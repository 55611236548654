import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  data: null,
  filters: {
    country: null,
    internalStatus: null,
    dateFrom: null,
    dateTo: null,
  },
  headers: [
    {
      text: "LOT",
      value: "lot",
    },
    {
      text: "INTERNAL STATUS",
      value: "internal_status",
    },
    {
      text: "COUNTRY",
      value: "country",
    },
    {
      text: "QUANTITY",
      value: "quantity",
      align: "end",
    },
    {
      text: "IUs/MGs",
      value: "iu",
      align: "end",
    },
    {
      text: "PRODUCT BRAND",
      value: "product_brand",
    },
    {
      text: "SHIPPING DATE",
      value: "date_shipped",
    },
    {
      text: "EXPIRATION DATE",
      value: "date_expiration",
    },
  ],
  productInternalStatus: null,
};

export const mutations = {
  resetFilters(state) {
    state.filters = {};
    state.filters = {
      country: null,
      internalStatus: null,
      dateFrom: null,
      dateTo: null,
    };
  },
  setData(state, data) {
    state.data = data;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
};

export const actions = {
  loadInternalStatus({ commit, state }) {
    return new Promise((resolve, reject) => {
      let internalStatus = state.filters.internalStatus;
      let country = state.filters.country;
      let expirationDateFrom = state.filters.dateFrom;
      let expirationDateTo = state.filters.dateTo;

      axios
        .post(
          "shipmentinternalstatus",
          {
            internalStatus,
            country,
            expirationDateFrom,
            expirationDateTo,
          },
          {
            signal: store.getters.abortSignal,
          }
        )
        .then((res) => {
          let data = res.data;

          commit("setData", data);
          resolve();
        })
        .catch((e) => {
          if (!e.message == "canceled") reject(e);
        });
    });
  },
};

export const getters = {
  data: (state) => state.data,
  filters: (state) => state.filters,
  headers: (state) => state.headers,
  productInternalStatus: (state) => state.productInternalStatus,
};
