import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  data: [],
  filters: {},
  isFilterLoading: false,
  dataDetails: null,
  isLoadingCount: 0,
  isDownloading: 0,
};
export const mutations = {
  setFilters(state, filters) {
    state.filters = filters;
  },
  setData(state, data) {
    state.data = data;
  },
  setDataDetails(state, dataDetails) {
    if (dataDetails == null) state.dataDetails = [];
    state.dataDetails = dataDetails;
  },
};
export const actions = {
  loadShippedVsReported({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/IUShippedVsUsed`, state.filters, {
          signal: store.getters.abortSignal,
        })
        .then((res) => {
          commit("setData", res.data);
          resolve();
        })
        .catch((e) => {
          if (!e.message == "canceled") reject(e);
        });
    });
  },
  loadDataDetails({ commit, state }, isExcelExport = false) {
    return new Promise((resolve, reject) => {
      if (isExcelExport) {
        state.isDownloading++;
        axios
          .post("IUShippedVsUsed/details?isExcelExport=true", state.filters, {
            signal: store.getters.abortSignal,
            responseType: "blob",
          })
          .then((res) => {
            state.isDownloading--;
            const blob = new Blob([res.data], { type: res.data.type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", "Utilization Rating Details.xlsx");
            link.click();
          })
          .catch((e) => {
            state.isDownloading--;
            if (!e.message == "canceled") reject(e);
          });
      } else {
        state.isLoadingCount++;
        axios
          .post("IUShippedVsUsed/details", state.filters, {
            signal: store.getters.abortSignal,
          })
          .then((res) => {
            state.isLoadingCount--;

            commit("setDataDetails", res.data);
            resolve();
          })
          .catch((e) => {
            state.isLoadingCount--;

            if (!e.message == "canceled") reject(e);
          });
      }
    });
  },
};
export const getters = {
  isFilterLoading: (state) => state.isFilterLoading,
  data: (state) =>
    state.data.sort((a, b) =>
      a.factorType < b.factorType ? -1 : a.factorType > b.factorType ? 1 : 0
    ),
  dataDetails: (state) => state.dataDetails,
  isLoading: (state) => state.isLoadingCount > 0,
  isDownloading: (state) => state.isDownloading > 0,
};
