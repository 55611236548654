import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  data: null,
  filters: {},
};
export const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
};
export const actions = {
  loadData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/donation", state.filters, {
          signal: store.getters.abortSignal,
        })
        .then((res) => {
          commit("setData", res.data);
          resolve();
        })
        .catch((e) => {
          if (!e.message == "canceled") reject(e);
        });
    });
  },
};
export const getters = {
  data: (state) => state.data,
};
