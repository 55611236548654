export const state = {
  dashboardKey: 0,
  customDashboardKey: 0,
  customDashboardGraphsKey: 0,
  addDialog: false,
  addGraph: false,
  selectedDashboard: {},
};

export const mutations = {
  setDashboardKey(state) {
    state.dashboardKey = state.dashboardKey + 1;
  },
  setCustomDashboardKey(state) {
    state.customDashboardKey = state.customDashboardKey + 1;
  },

  setAddDialog(state, addDialog) {
    state.addDialog = addDialog;
  },
  setAddGraph(state, addGraph) {
    state.addGraph = addGraph;
  },
  setSelectedDashboard(state, selectedDashboard) {
    state.selectedDashboard = selectedDashboard;
  },
};

export const actions = {};

export const getters = {
  dashboardKey: (state) => state.dashboardKey,
  customDashboardKey: (state) => state.customDashboardKey,
  addDialog: (state) => state.addDialog,
  addGraph: (state) => state.addGraph,
  selectedDashboard: (state) => state.selectedDashboard,
};
