export function formatTwoDecimal(value) {
  return formatDecimal(value, 2);
}
export function formatDecimal(value, rounding = 2) {
  return isNaN(value) || value == null
    ? value
    : Number(value).toFixed(rounding);
}

export function formatFinancialAmounts(value) {
  return isNaN(value) || value == null
    ? value
    : Number(value).toLocaleString("en-US", { minimumFractionDigits: 0 });
}
export function formatFinancialAmountsFractions(value) {
  return isNaN(value) || value == null
    ? value
    : Number(value).toLocaleString("en-US", { maximumFractionDigits: 2 });
}
export function formatWithPrecision(value, fieldRoundingPrecision) {
  return isNaN(value) || value == null
    ? value
    : Number(value).toLocaleString("en-US", {
        minimumFractionDigits: fieldRoundingPrecision
          ? fieldRoundingPrecision
          : 0,
      });
}
