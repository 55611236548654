import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  filters: {},
  productInStock: null,
  isLoadingCount: 0,
  isDownloading: 0,
  isPrintingCount: 0,
};

export const mutations = {
  setFilters(state, filters) {
    state.filters = filters;
  },
  setProductInStock(state, productInStock) {
    if (productInStock == null) state.productInStock = [];

    state.productInStock = productInStock;
  },
};

export const actions = {
  loadIUsInStock({ commit, state }, parameters) {
    return new Promise((resolve, reject) => {
      if (parameters.isExcelExport) {
        state.isDownloading++;
        axios
          .post("iustock?isExcelExport=true", state.filters, {
            signal: store.getters.abortSignal,
            responseType: "blob",
          })
          .then((res) => {
            state.isDownloading--;
            const blob = new Blob([res.data], { type: res.data.type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute(
              "download",
              "Vials & IUs/MGs remaining in stock.xlsx"
            );
            link.click();
          })
          .catch((e) => {
            state.isDownloading--;
            if (!e.message == "canceled") reject(e);
          });
      } else {
        if (parameters.isPrinting) state.isPrintingCount++;
        else state.isLoadingCount++;

        axios
          .post("iustock", state.filters, {
            signal: store.getters.abortSignal,
          })
          .then((res) => {
            if (parameters.isPrinting) state.isPrintingCount--;
            else state.isLoadingCount--;
            commit("setProductInStock", res.data);
            resolve();
          })
          .catch((e) => {
            if (parameters.isPrinting) state.isPrintingCount--;
            else state.isLoadingCount--;
            if (!e.message == "canceled") reject(e);
          });
      }
    });
  },
};

export const getters = {
  filters: (state) => state.filters,
  productInStock: (state) => state.productInStock,
  isLoading: (state) => state.isLoadingCount > 0,
  isDownloading: (state) => state.isDownloading > 0,
};
