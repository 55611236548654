import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  filters: {},
  shippedItems: null,
  isLoadingCount: 0,
  isDownloading: 0,
};

export const mutations = {
  setFilters(state, filters) {
    state.filters = filters;
  },
  setShippedItems(state, shippedItems) {
    if (shippedItems == null) state.shippedItems = [];
    state.shippedItems = shippedItems;
  },
};

export const actions = {
  loadIUsShipped({ commit, state }, isExcelExport = false) {
    return new Promise((resolve, reject) => {
      if (isExcelExport) {
        state.isDownloading++;
        axios
          .post("iushipped?isExcelExport=true", state.filters, {
            signal: store.getters.abortSignal,
            responseType: "blob",
          })
          .then((res) => {
            state.isDownloading--;
            const blob = new Blob([res.data], { type: res.data.type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", "Vials & IUs/MGs shipped.xlsx");
            link.click();
          })
          .catch((e) => {
            state.isDownloading--;
            if (!e.message == "canceled") reject(e);
          });
      } else {
        state.isLoadingCount++;
        axios
          .post("iushipped", state.filters, {
            signal: store.getters.abortSignal,
          })
          .then((res) => {
            state.isLoadingCount--;

            commit("setShippedItems", res.data);
            resolve();
          })
          .catch((e) => {
            state.isLoadingCount--;

            if (!e.message == "canceled") reject(e);
          });
      }
    });
  },
};

export const getters = {
  filters: (state) => state.filters,
  shippedItems: (state) => state.shippedItems,
  isLoading: (state) => state.isLoadingCount > 0,
  isDownloading: (state) => state.isDownloading > 0,
};
