import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";
import { ROUTE_NAMES } from "../utils/const";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");
const Home = () => import("@/views/Home");

// Reports - Mario
const CountriesDashboardHeader = () =>
  import("@/views/reports/CountriesDashboardHeader");
const CountryKPIHeader = () => import("@/views/reports/CountryKPIHeader");
const VialsIUsReceivedMultiselect = () =>
  import("@/views/reports/VialsIUsReceivedMultiselect");
const VialsIUsRemainingInStockHeader = () =>
  import("@/views/reports/VialsIUsRemainingInStockHeader");
const Users = () => import("@/views/Users/Users");
const Warehouses = () => import("@/views/Warehouses/Warehouses");
const DonationPlanProductType = () =>
  import("@/views/DonationPlanProductType/DonationPlanProductType");
const StocksQuaterlyReports = () =>
  import("@/views/Stocks/StocksQuaterlyReports");

const VialsIUsShipping = () => import("@/views/reports/VialsIUsShipping");
const ShippedvsReported = () => import("@/views/reports/ShippedvsReported");
const InternalStatus = () => import("@/views/reports/InternalStatus");

const EmptyLayout = () => import("../views/EmptyLayout.vue");
const Login = () => import("../views/Auth/Login.vue");

const ResetPassword = () => import("../views/Auth/ResetPassword.vue");
const TrendOfDonations = () => import("@/views/reports/TrendOfDonations");
//const GNIPerCapita = () => import("@/views/reports/GNIPerCapita")
const PatientsTreated = () => import("@/views/reports/PatientsTreated");
//const PatientTreatedByAgeGroup = () => import("@/views/reports/PatientTreatedByAgeGroup")

//new
const RegionalReportPage = () => import("@/views/reports/RegionalReportPage");
const ShippingStatusReportPage = () =>
  import("@/views/reports/ShippingStatusReportPage");
const CustomDashboardListPage = () =>
  import("@/views/reports/CustomDashboardListPage");
const CustomDashboardPage = () => import("@/views/reports/CustomDashboardPage");
const DonationPlanPage = () => import("@/views/reports/DonationPlanPage");
const QuaterlyStockReportPage = () =>
  import("@/views/reports/QuaterlyStockReportPage");

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/login",
      component: EmptyLayout,
      children: [
        {
          path: "",
          name: "Login",
          component: Login,
        },
        {
          path: "/resetpassword",
          name: "ResetPassword",
          component: ResetPassword,
        },
      ],
    },
    {
      path: "",
      name: "The Container",
      component: TheContainer,
      meta: { authorize: [] },
      children: [
        {
          path: "/",
          name: "Home",
          component: Home,
          meta: { authorize: [] },
        },
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: { authorize: [] },
        },
        {
          path: "/countriesdashboard",
          name: "Country Dashboard",
          component: CountriesDashboardHeader,
          meta: { authorize: [] },
        },
        {
          path: "/countrykpi",
          name: ROUTE_NAMES.COUNTRY_REPORT_CARD,
          component: CountryKPIHeader,
          //meta: { authorize: [], printable: true },
          meta: { authorize: [] },
        },
        {
          path: "/received-data",
          name: "Vials/Units Received",
          component: VialsIUsReceivedMultiselect,
          meta: { authorize: [] },
        },
        {
          path: "/instock",
          name: "Vials/Units In Stock",
          component: VialsIUsRemainingInStockHeader,
          meta: { authorize: [] },
        },

        {
          path: "/shipping",
          name: "Vials/Units Shipped",
          component: VialsIUsShipping,
          meta: { authorize: [] },
        },

        {
          path: "/shippedvsreported",
          name: "Utilization Rating",
          component: ShippedvsReported,
          meta: { authorize: [] },
        },

        {
          path: "/internalstatus",
          name: "Internal Status",
          component: InternalStatus,
          meta: { authorize: [] },
        },

        {
          path: "/expirydatesquarterly",
          name: "Quarterly Stock Report",
          component: QuaterlyStockReportPage,
          meta: { authorize: [] },
        },

        {
          path: "/trendofdonations",
          name: "Trend of donations",
          component: TrendOfDonations,
          meta: { authorize: [] },
        },
        // {
        //     path: "/gnipercapita",
        //     name: "GNI Per Capita",
        //     component: GNIPerCapita
        // },
        {
          path: "/patientstreated",
          name: "Patients Treated",
          component: PatientsTreated,
          meta: { authorize: [] },
        },
        {
          path: "/regionalreport",
          name: "Regional Report",
          component: RegionalReportPage,
          meta: { authorize: [] },
        },
        {
          path: "/shippingstatusreport",
          name: "Shipping Status Report",
          component: ShippingStatusReportPage,
          meta: { authorize: [] },
        },
        {
          path: "/customdashboardlist",
          name: "Custom Dashboards",
          component: CustomDashboardListPage,
          meta: { authorize: [] },
        },
        {
          path: "/customdashboard",
          name: "Custom Dashboard",
          component: CustomDashboardPage,
          meta: { authorize: [] },
        },
        {
          path: "/donationplan",
          name: "Donation Plan",
          component: DonationPlanPage,
          meta: { authorize: [] },
        },
        {
          path: "/users/list",
          name: "Users",
          component: Users,
          meta: { authorize: [] },
        },
        {
          path: "/warehouses/list",
          name: "Warehouses",
          component: Warehouses,
          meta: { authorize: [] },
        },
        {
          path: "/donationPlanProductType",
          name: "DonationPlanProductType",
          component: DonationPlanProductType,
          meta: { authorize: [] },
        },
        {
          path: "/stocks/report",
          name: "stocks",
          component: StocksQuaterlyReports,
          meta: { authorize: [] },
        },
      ],
    },
    {
      path: "/instock",
      //redirect: "/instock",
      name: "VialsIUsRemainingInStockHeader",
      component: VialsIUsRemainingInStockHeader,
      meta: { authorize: [] },
    },
    {
      path: "/*",
      redirect: (to) => {
        return { path: "/" };
      },
    },
  ];
}

router.afterEach((to, from) => {
  store.dispatch("setFilter");
});

router.beforeEach((to, from, next) => {
  store.dispatch("auth/tryAutoLogin");
  if (from.name !== "Login") store.dispatch("abort");
  store.dispatch("init").then(() => {
    //Redirect to login page if not logged in and trying to access a restricted page
    if (to.meta?.authorize) {
      //Check if user logged in
      if (!store.getters["auth/getIsAuthenticated"]) {
        return next({ name: "Login", query: { returnUrl: to.path } });
      } else {
        if (
          to.meta?.authorize.length &&
          !to.meta?.authorize.includes(
            store.getters["auth/getLoggedInUserRole"]
          )
        ) {
          //Role not authorised so redirect to home page
          return next({ name: "Home" });
        }
      }
    }

    return next();
  });
});

export default router;
