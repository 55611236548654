import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  iuByFactorType: null,
  iuByPotency: null,
  potencyLabels: [250, 500, 750, 1000, 1500, 2000, 3000],
  productReceived: null,
  filters: {},
  isLoadingCount: 0,
  isDownloading: 0,
};

export const mutations = {
  setFilters(state, filters) {
    state.filters = filters;
  },
  setProductReceived(state, productReceived) {
    if (productReceived == null) state.productReceived = [];

    state.productReceived = productReceived;
  },
};

export const actions = {
  loadIUsReceived({ commit, state }, isExcelExport = false) {
    return new Promise((resolve, reject) => {
      if (isExcelExport) {
        state.isDownloading++;
        axios
          .post("iureceived?isExcelExport=true", state.filters, {
            signal: store.getters.abortSignal,
            responseType: "blob",
          })
          .then((res) => {
            state.isDownloading--;
            const blob = new Blob([res.data], { type: res.data.type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", "Vials & IUs/MGs received.xlsx");
            link.click();
          })
          .catch((e) => {
            state.isDownloading--;
            if (!e.message == "canceled") reject(e);
          });
      } else {
        state.isLoadingCount++;
        axios
          .post("iureceived", state.filters, {
            signal: store.getters.abortSignal,
          })
          .then((res) => {
            state.isLoadingCount--;

            commit("setProductReceived", res.data);
            resolve();
          })
          .catch((e) => {
            state.isLoadingCount--;
            if (!e.message == "canceled") reject(e);
          });
      }
    });
  },
};

export const getters = {
  filters: (state) => state.filters,
  iuByFactorType: (state) => state.iuByFactorType,
  iuByPotency: (state) => state.iuByPotency,
  potencyLabels: (state) => state.potencyLabels,
  productReceived: (state) => state.productReceived,
  isLoading: (state) => state.isLoadingCount > 0,
  isDownloading: (state) => state.isDownloading > 0,
};
