export const state = {
  addDialog: false,
  editDialog: false,
  selectedGraphId: null,
  selectedGraphIdToEdit: null,
  graphToRemove: null,
};

export const mutations = {
  setAddDialog(state, addDialog) {
    state.addDialog = addDialog;
  },
  setEditDialog(state, editDialog) {
    state.editDialog = editDialog;
  },
  setSelectedGraphId(state, selectedGraphId) {
    state.selectedGraphId = selectedGraphId;
  },
  setSelectedGraphIdToEdit(state, selectedGraphIdToEdit) {
    state.selectedGraphIdToEdit = selectedGraphIdToEdit;
  },
  setGraphToRemove(state, graphToRemove) {
    state.graphToRemove = graphToRemove;
  },
};

export const actions = {};

export const getters = {
  addDialog: (state) => state.addDialog,
  editDialog: (state) => state.editDialog,
  selectedGraphId: (state) => state.selectedGraphId,
  selectedGraphIdToEdit: (state) => state.selectedGraphIdToEdit,
  graphToRemove: (state) => state.graphToRemove,
};
