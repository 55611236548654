import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  data: null,
  filters: {},
  isLoadingCount: 0,
};

export const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
};

export const actions = {
  loadDonationsTrends({ commit }) {
    return new Promise((resolve, reject) => {
      state.isLoadingCount++;
      axios
        .post("/QuarterlyReport/Trend-Of-Donations-By-Quarter", state.filters, {
          signal: store.getters.abortSignal,
        })
        .then((res) => {
          state.isLoadingCount--;
          commit("setData", res.data);
          resolve();
        })
        .catch((e) => {
          state.isLoadingCount--;
          if (!e.message == "canceled") reject(e);
        });
    });
  },
};

export const getters = {
  data: (state) => state.data,
  filters: (state) => state.filters,
  selectedCountry: (state) => state.filters.country || null,
  isLoading: (state) => state.isLoadingCount > 0,
};
