import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context("./modules", false, /.*\.js$/);

const modules = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }

    return {
      ...modules,
      [name]: module,
    };
  }, {});

const store = new Vuex.Store({
  modules,
  state: {
    isLoading: false,
    isPrinting: false,
    sidebarShow: "responsive",
    sidebarMinimize: false,
    environment: process.env.VUE_APP_BACKEND_URL,
    refreshGraphKey: 0,
    abortController: new AbortController(),
  },
  mutations: {
    setRefreshGraphKey(state) {
      state.refreshGraphKey = state.refreshGraphKey + 1;
    },
    setLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarOpened ? false : "responsive";
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarClosed ? true : "responsive";
    },
    set(state, [variable, value]) {
      state[variable] = value;
    },
    setIsPrinting(state, isPrinting) {
      state.isPrinting = isPrinting;
    },
  },
  actions: {
    abort({ commit, state }) {
      state.abortController.abort();
      state.abortController = new AbortController();
    },
    init({ dispatch }) {},
    setFilter({ dispatch }) {
      return Promise.all([
        //dispatch("globalFilter/resetFilters"),
        // dispatch("globalFilter/loadGlobalFilter"),
      ]);
    },
    download(context, { blob, filename, applicationType }) {
      blob = new Blob([blob], {
        type: applicationType,
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      //URL.revokeObjectURL(link.href);
    },
  },
  getters: {
    isPrinting: (state) => state.isPrinting,
    isLoading: (state) => state.isLoading,
    environment: (state) => state.environment,
    refreshGraphKey: (state) => state.refreshGraphKey,
    // cancelSource: state => state.cancelToken.source(),
    // cancelToken(state, getters) {
    //     return getters.cancelSource.token;
    // },
    //abortController: state => state.abortController,
    abortSignal: (state) => state.abortController.signal,
  },
});

export default store;
