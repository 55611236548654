import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  data: null,
  filters: {},
  isLoadingCount: 0,
};
export const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
};
export const actions = {
  loadPatientsTreated({ commit }) {
    return new Promise((resolve, reject) => {
      state.isLoadingCount++;
      axios
        .post("/combinedKPI", state.filters, {
          signal: store.getters.abortSignal,
        })
        .then((res) => {
          state.isLoadingCount--;
          commit(
            "setData",
            res.data.filter((x) => x.year >= 2018)
          );
          resolve();
        })
        .catch((e) => {
          state.isLoadingCount--;
          if (!e.message == "canceled") reject(e);
        });
    });
  },
};
export const getters = {
  isLoading: (state) => state.isLoadingCount > 0,
  totalPerYear: (state, getters, rootState) => {
    let cumulative = rootState["patientsTreated"]["cumulative"];
    let prohy = rootState["patientsTreated"]["prophylaxisCumulative"];
    let result = {};
    let sum = 0;
    Object.keys(cumulative).forEach((key) => {
      let current = cumulative[key] + prohy[key];
      sum += current;
      result[key] = sum;
    });

    return result;
  },

  data: (state) => {
    if (state.data == null) return [];
    let temp = [].concat(state.data);

    return temp.sort((a, b) => {
      if (a.year > b.year) return 1;
      else if (a.year < b.year) return -1;

      if (a.quarter > b.quarter) return 1;
      else if (a.quarter < b.quarter) return -1;

      return 0;
    });
  },
};
