import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  cumulative: null,
  filters: {},
  prophylaxisCumulative: null,
};
export const mutations = {
  setCumulative(state, data) {
    if (data != null) state.cumulative = data;
  },
  setProphylaxisCumulative(state, data) {
    if (data != null) state.prophylaxisCumulative = data;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
};
export const actions = {
  loadPatientsTreated({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .post("patientstreated", state.filters, {
          signal: store.getters.abortSignal,
        })
        .then((res) => {
          let data = res.data;
          commit("setCumulative", data);
          resolve();
        })
        .catch((e) => {
          if (!e.message == "canceled") reject(e);
        });
    });
  },
};
export const getters = {
  cumulative: (state) => [].concat(state.cumulative ?? []),
  prophylaxisCumulative: (state) => state.prophylaxisCumulative,
};

const loadHistoricalData = (country, yearFrom, yearTo) => {};
