import axios from "../../plugins/axios";
import store from "../index";
import { formatFinancialAmounts } from "../../utils/format.js";

export const state = {
  potencyLabels: [250, 500, 750, 1000, 1500, 2000, 3000],
  operationMetrics: null,
  impactMetrics: null,
  filters: {},
  isLoadingCount: 0,
};

export const mutations = {
  setFilters(state, filters) {
    state.filters = filters;
  },

  setOperationMetrics(state, operationMetrics) {
    if (operationMetrics == null) state.operationMetrics = [];

    state.operationMetrics = operationMetrics;

    state.operationMetrics.forEach((metric) => {
      metric.data.forEach((data) => {
        if (data.value == null) data.value = "N/A";
        else data.value = formatFinancialAmounts(data.value);
      });
    });
  },
  setImpactMetrics(state, impactMetrics) {
    if (impactMetrics == null) state.impactMetrics = [];

    state.impactMetrics = impactMetrics;

    state.impactMetrics.forEach((metric) => {
      metric.data.forEach((data) => {
        if (data.value == null) data.value = "N/A";
        else data.value = formatFinancialAmounts(data.value);
      });
    });
  },
};

export const actions = {
  loadOperationMetrics({ commit, state }) {
    return new Promise((resolve, reject) => {
      state.isLoadingCount++;
      axios
        .post("CombinedKPI/GetKPIOperationMetric", state.filters, {
          signal: store.getters.abortSignal,
        })
        .then((res) => {
          state.isLoadingCount--;

          commit("setOperationMetrics", res.data);
          resolve();
        })
        .catch((e) => {
          state.isLoadingCount--;
          if (!e.message == "canceled") reject(e);
        });
    });
  },

  loadImpactMetrics({ commit, state }) {
    return new Promise((resolve, reject) => {
      state.isLoadingCount++;
      axios
        .post("CombinedKPI/GetKPIImpactMetric", state.filters, {
          signal: store.getters.abortSignal,
        })
        .then((res) => {
          state.isLoadingCount--;

          commit("setImpactMetrics", res.data);
          resolve();
        })
        .catch((e) => {
          state.isLoadingCount--;
          if (!e.message == "canceled") reject(e);
        });
    });
  },
};

export const getters = {
  filters: (state) => state.filters,
  potencyLabels: (state) => state.potencyLabels,
  operationMetrics: (state) => state.operationMetrics,
  impactMetrics: (state) => state.impactMetrics,
  isLoading: (state) => state.isLoadingCount > 0,
};
