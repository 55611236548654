<template>
  <v-app @contextmenu="hideContext($event)">
    <router-view></router-view>
    <div class="text-center">
      <v-dialog v-model="isLoading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import store from "./store/index";

export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
  },
  watch: {},
  methods: {
    hideContext(e) {
      if (process.env.NODE_ENV == "production") e.preventDefault();
    },
  },
  async mounted() {
    //Old filter
    // store.dispatch("globalFilter/resetFilters");
    // if( this.$store.getters['auth/getIsAuthenticated'] ) await this.$store.dispatch("globalFilter/loadGlobalFilter");
    // const filters = sessionStorage.getItem('filters');
    // if (filters) this.$store.commit("globalFilter/setFilters", JSON.parse(filters));

    //Dimension
    store.dispatch("globalDimensionFilter/resetFilters");
    if (this.$store.getters["auth/getIsAuthenticated"])
      await this.$store.dispatch("globalDimensionFilter/loadGlobalFilter");
    const dimensionFilters = sessionStorage.getItem("dimensionFilters");
    if (dimensionFilters)
      this.$store.commit(
        "globalDimensionFilter/setFilters",
        JSON.parse(dimensionFilters)
      );
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
