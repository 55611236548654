import axios from "@/plugins/axios";
import { ActionContext } from "vuex";

const initialState = () => ({
  snackbar: {},
  adminSetting: false,
});

export const state = initialState();

export const mutations = {
  RESET(state) {
    state = initialState();
  },
  SET_SNACK_WHOLE(state, payload) {
    state.snackbar = payload;
  },
  SET_SNACK_ISSNACKING(state, payload) {
    state.snackbar.isSnacking = payload;
  },
  SET_SNACK_COLOR(state, payload) {
    state.snackbar.snackColor = payload;
  },
  SET_SNACK_MESSAGE(state, payload) {
    state.snackbar.snackMessage = payload;
  },
  SET_SNACK_TIMEOUT(state, payload) {
    state.snackbar.snackTimeout = payload;
  },
  SET_ADMIN_SETTING(state, payload) {
    state.adminSetting = payload;
  },
};

export const actions = {
  reset({ commit }) {
    commit("RESET");
  },
  changeSnackbar({ commit }, payload) {
    commit("SET_SNACK_WHOLE", payload);
  },
  changeSnackIsSnacking({ commit }, payload) {
    commit("SET_SNACK_ISSNACKING", payload);
  },
  changeSnackColor({ commit }, payload) {
    commit("SET_SNACK_COLOR", payload);
  },
  changeSnackMessage({ commit }, payload) {
    commit("SET_SNACK_MESSAGE", payload);
  },
  changeSnackTimeout({ commit }, payload) {
    commit("SET_SNACK_TIMEOUT", payload);
  },
  changeAdminSetting({ commit }, payload) {
    commit("SET_ADMIN_SETTING", payload);
  },
};

export const getters = {
  getIsSnacking: (state) => state.snackbar.isSnacking,
  getSnackColor: (state) => state.snackbar.snackColor,
  getSnackMessage: (state) => state.snackbar.snackMessage,
  getSnackTimeout: (state) => state.snackbar.snackTimeout,
  getWholeSnack: (state) => state.snackbar,
  getadminSetting: (state) => state.adminSetting,
};
