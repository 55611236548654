export const state = {
  userKey: 0,
};

export const mutations = {
  setUserKey(state, payload) {
    state.userKey = state.userKey + 1;
  },
};
export const getters = {
  userKey: (state) => state.userKey,
};
