import axios from "../../plugins/axios";
import store from "../../store/index";

export const state = {
  filterLoading: false,
  countries: [],
  factorTypes: [],
  manufacturers: [],
  productBrands: [],
  regions: [],
  warehouses: [],
  lotNumbers: [],
  years: [],
  prophylaxisStatus: [
    { value: true, name: "With prophylaxis" },
    { value: false, name: "Without prophylaxis" },
  ],
  months: [
    {
      label: "January",
      value: 1,
    },
    {
      label: "February",
      value: 2,
    },
    {
      label: "March",
      value: 3,
    },
    {
      label: "April",
      value: 4,
    },
    {
      label: "May",
      value: 5,
    },
    {
      label: "June",
      value: 6,
    },
    {
      label: "July",
      value: 7,
    },
    {
      label: "August",
      value: 8,
    },
    {
      label: "September",
      value: 9,
    },
    {
      label: "October",
      value: 10,
    },
    {
      label: "November",
      value: 11,
    },
    {
      label: "December",
      value: 12,
    },
  ],
  orderFulfillmentStatuses: null,
  quarters: [
    {
      label: "Q1",
      value: 1,
    },
    {
      label: "Q2",
      value: 2,
    },
    {
      label: "Q3",
      value: 3,
    },
    {
      label: "Q4",
      value: 4,
    },
  ],
  filters: {
    productBrands: [],
    manufacturers: [],
    factorTypes: [],
    warehouses: [],
    lotNumbers: [],
    prophylaxisStatus: [],
    dateFilterToggle: 0,
    dateFrom: {
      year: null,
      month: null,
      quarter: null,
    },
    dateTo: {
      year: null,
      month: null,
      quarter: null,
    },
    country: [],
    region: [],
    ageFrom: null,
    ageTo: null,
    withProphylaxis: null,
  },
  collapseFilters: false,
  filterChanged: false,
  onFilterChange: 0,
};

export const mutations = {
  setFilterLoading(state, value) {
    state.filterLoading = value;
  },
  resetFilters(state) {
    var currentManufacturer = [];
    // if (state.manufacturers.length === 1)
    //   currentManufacturer.push(state.manufacturers[0]);

    state.filters = {};
    state.filters = {
      productBrands: [],
      manufacturers: currentManufacturer,
      factorTypes: [],
      warehouses: [],
      lotNumbers: [],
      dateFilterToggle: 0,
      dateFrom: {
        year: null,
        month: null,
        quarter: null,
      },
      dateTo: {
        year: null,
        month: null,
        quarter: null,
      },
      country: [],
      region: [],
      ageFrom: null,
      ageTo: null,
      withProphylaxis: null,
    };
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setFilterChanged(state, filterChanged) {
    state.filterChanged = filterChanged;
  },
  setCollapseFilters(state, collapseFilters) {
    state.collapseFilters = collapseFilters;
  },
  setCountries(state, countries) {
    if (countries == null) countries = [];
    state.countries = countries;
  },
  setFactorTypes(state, factorTypes) {
    if (factorTypes == null) state.factorTypes = [];

    state.factorTypes = factorTypes;
  },
  setManufacturers(state, manufacturers) {
    if (manufacturers == null) state.manufacturers = [];
    state.manufacturers = manufacturers;
  },
  setProphylaxisStatus(state, prophylaxisStatus) {
    if (prophylaxisStatus == null) state.prophylaxisStatus = [];
    state.prophylaxisStatus = prophylaxisStatus;
  },
  setOrderFulfillmentStatuses(state, orderFulfillmentStatuses) {
    if (orderFulfillmentStatuses == null) state.orderFulfillmentStatuses = [];

    state.orderFulfillmentStatuses = orderFulfillmentStatuses;
  },
  setProductBrands(state, productBrands) {
    if (productBrands == null) state.productBrands = [];

    state.productBrands = productBrands;
  },
  setRegions(state, regions) {
    if (regions == null) state.regions = [];

    state.regions = regions;
  },
  setWarehouses(state, warehouses) {
    if (warehouses == null) state.warehouses = [];

    state.warehouses = warehouses;
  },
  setLotNumbers(state, lotNumbers) {
    if (lotNumbers == null) state.lotNumbers = [];

    state.lotNumbers = lotNumbers;
  },
  setYears(state, years) {
    let start = new Date(years).getFullYear();
    let end = new Date(Date.now()).getFullYear();

    state.years = Array(end - start + 1)
      .fill()
      .map((_, idx) => end - idx);

    let minYear = Math.min(...state.years);
    if (
      state.filters.dateFrom.year != null &&
      state.filters.dateFrom.year < minYear
    )
      state.filters.dateFrom.year = minYear;

    if (
      state.filters.dateTo.year != null &&
      state.filters.dateTo.year < minYear
    )
      state.filters.dateTo.year = minYear;
  },
  setOnFilterChange(state) {
    state.onFilterChange += 1;
  },
};

export const actions = {
  resetFilters({ commit, state }) {
    commit("resetFilters");
  },

  loadGlobalFilter({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("setFilterLoading", true);
      axios
        .post("data/globaldimensionfilter", state.filters, {
          signal: store.getters.abortSignal,
        })
        .then((res) => {
          var countries = res.data.country;
          commit("setCountries", countries);

          let years = res.data.minimumDataDate;
          commit("setYears", years);

          let factorTypes = res.data.factorTypes;
          commit("setFactorTypes", factorTypes);

          let manufacturers = res.data.manufacturers;

          let productBrands = res.data.productBrands;
          commit("setProductBrands", productBrands);

          let regions = res.data.region;
          commit("setRegions", regions);

          let warehouses = res.data.warehouses;
          commit("setWarehouses", warehouses);

          let lotNumbers = res.data.lotNumbers;
          commit("setLotNumbers", lotNumbers);

          commit("setFilterLoading", false);

          const manufacturerUserID = store.getters["auth/getLoggedInUserOrgId"];
          if (
            manufacturerUserID === "null" ||
            manufacturerUserID == undefined
          ) {
            commit("setManufacturers", manufacturers);
          } else {
            const filtermanufacturer = manufacturers.filter(
              (item) => item.id == manufacturerUserID
            );
            commit("setManufacturers", filtermanufacturer);
          }

          resolve();
        })
        .catch((e) => {
          commit("setFilterLoading", false);
          if (!e.message == "canceled") reject(e);
        });
    });
  },
};

export const getters = {
  countries: (state) => state.countries,
  factorTypes: (state) => state.factorTypes,
  manufacturers: (state) => state.manufacturers,
  prophylaxisStatus: (state) => state.prophylaxisStatus,
  months: (state) => state.months,
  orderFulfillmentStatuses: (state) => state.orderFulfillmentStatuses,
  productBrands: (state) => state.productBrands,
  quarters: (state) => state.quarters,
  regions: (state) => state.regions,
  years: (state) => state.years,
  warehouses: (state) => state.warehouses,
  lotNumbers: (state) => state.lotNumbers,
  filters: (state) => state.filters,
  filterChanged: (state) => state.filterChanged,
  collapseFilters: (state) => state.collapseFilters,
  isFilterLoading: (state) => state.filterLoading,
  onFilterChange: (state) => state.onFilterChange,
  formatedFilters: (state) => {
    let dateFrom = {
      year: null,
      month: null,
      quarter: null,
    };
    if (state.filters.dateFrom.year) {
      dateFrom = {
        year: state.filters.dateFrom.year,
        month:
          state.filters.dateFilterToggle == 1
            ? state.filters.dateFrom.month
            : null,
        quarter:
          state.filters.dateFilterToggle == 2
            ? state.filters.dateFrom.quarter
            : null,
      };
    }

    let dateTo = {
      year: null,
      month: null,
      quarter: null,
    };
    if (state.filters.dateTo.year) {
      dateTo = {
        year: state.filters.dateTo.year,
        month:
          state.filters.dateFilterToggle == 1
            ? state.filters.dateTo.month
            : null,
        quarter:
          state.filters.dateFilterToggle == 2
            ? state.filters.dateTo.quarter
            : null,
      };
    }
    return {
      Manufacturers: state.filters.manufacturers,
      ProductBrands: state.filters.productBrands,
      FactorTypes: state.filters.factorTypes,
      Warehouses: state.filters.warehouses,
      LotNumbers: state.filters.lotNumbers,
      DateFrom: dateFrom,
      DateTo: dateTo,
      Country: state.filters.country,
      Region: state.filters.region,
      AgeFrom: state.filters.ageFrom,
      AgeTo: state.filters.ageTo,
      WithProphylaxis: state.filters.withProphylaxis,
    };
  },
};
