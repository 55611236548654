import axios from "../../plugins/axios";

export const state = {
  backgroundColors: [
    "#ED174C",
    "#1F3F78",
    "#798E9A",
    "#afafaf",
    "#8b1e6f",
    "#0067ab",
    "#183474",
    "#5e8fa6",
    "#5e66a6",
    "#684d96",
    "#794d96",
  ],
  countries: null,
  factorTypes: null,
  manufacturers: null,
  months: [
    {
      label: "January",
      value: 1,
    },
    {
      label: "February",
      value: 2,
    },
    {
      label: "March",
      value: 3,
    },
    {
      label: "April",
      value: 4,
    },
    {
      label: "May",
      value: 5,
    },
    {
      label: "June",
      value: 6,
    },
    {
      label: "July",
      value: 7,
    },
    {
      label: "August",
      value: 8,
    },
    {
      label: "September",
      value: 9,
    },
    {
      label: "October",
      value: 10,
    },
    {
      label: "November",
      value: 11,
    },
    {
      label: "December",
      value: 12,
    },
  ],
  orderFulfillmentStatuses: null,
  products: null,
  quarters: [
    {
      label: "Q1",
      value: 1,
    },
    {
      label: "Q2",
      value: 2,
    },
    {
      label: "Q3",
      value: 3,
    },
    {
      label: "Q4",
      value: 4,
    },
  ],
  regions: null,
  warehouses: null,
};

export const mutations = {
  setCountries(state, countries) {
    if (countries == null) countries = [];
    state.countries = countries;
  },
  setFactorTypes(state, factorTypes) {
    if (factorTypes == null) state.factorTypes = [];

    state.factorTypes = factorTypes;
  },
  setManufacturers(state, manufacturers) {
    if (manufacturers == null) state.manufacturers = [];

    state.manufacturers = manufacturers;
  },
  setOrderFulfillmentStatuses(state, orderFulfillmentStatuses) {
    if (orderFulfillmentStatuses == null) state.orderFulfillmentStatuses = [];

    state.orderFulfillmentStatuses = orderFulfillmentStatuses;
  },
  setProducts(state, products) {
    if (products == null) state.products = [];

    state.products = products;
  },
  setRegions(state, regions) {
    if (regions == null) state.regions = [];

    state.regions = regions;
  },
  setWarehouses(state, warehouses) {
    if (warehouses == null) state.warehouses = [];

    state.warehouses = warehouses;
  },
};

export const actions = {
  loadCountries({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.countries) {
        return resolve();
      }

      axios
        .get("data/countries")
        .then((res) => {
          var countries = res.data;
          commit("setCountries", countries);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  loadFactorTypes({ commit, state }) {
    if (!state.factorTypes) {
      return new Promise((resolve, reject) => {
        axios
          .get("Data/factor-types")
          .then((res) => {
            let factorTypes = res.data;
            commit("setFactorTypes", factorTypes);
            resolve();
          })
          .catch((e) => reject(e));
      });
    }
  },
  loadManufacturers({ commit, state }) {
    if (!state.manufacturers) {
      return new Promise((resolve, reject) => {
        axios
          .get("Data/manufacturers")
          .then((res) => {
            let manufacturers = res.data;
            commit("setManufacturers", manufacturers);
            resolve();
          })
          .catch((e) => reject(e));
      });
    }
  },
  loadOrderFulfillmentStatuses({ commit, state }) {
    if (!state.orderFulfillmentStatuses) {
      return new Promise((resolve, reject) => {
        axios
          .get("Data/orderfulfillment-status")
          .then((res) => {
            let orderFulfillmentStatuses = res.data;
            commit("setOrderFulfillmentStatuses", orderFulfillmentStatuses);
            resolve();
          })
          .catch((e) => reject(e));
      });
    }
  },
  loadProducts({ commit, state }) {
    if (!state.products) {
      return new Promise((resolve, reject) => {
        axios
          .get("Data/productbrands")
          .then((res) => {
            let products = res.data;
            commit("setProducts", products);
            resolve();
          })
          .catch((e) => reject(e));
      });
    }
  },
  loadRegions({ commit, state }) {
    if (!state.regions) {
      return new Promise((resolve, reject) => {
        axios
          .get("Data/regions")
          .then((res) => {
            let regions = res.data;
            commit("setRegions", regions);
            resolve();
          })
          .catch((e) => reject(e));
      });
    }
  },
  loadWarehouses({ commit, state }) {
    if (!state.warehouses) {
      return new Promise((resolve, reject) => {
        axios
          .get("Data/warehouses")
          .then((res) => {
            let warehouses = res.data;
            commit("setWarehouses", warehouses);
            resolve();
          })
          .catch((e) => reject(e));
      });
    }
  },
  loadGeneralData({ commit, state }) {
    this.dispatch("generalData/loadManufacturers"),
      this.dispatch("generalData/loadWarehouses"),
      this.dispatch("generalData/loadProducts"),
      this.dispatch("generalData/loadFactorTypes"),
      this.dispatch("generalData/loadCountries"),
      this.dispatch("generalData/loadRegions"),
      this.dispatch("generalData/loadOrderFulfillmentStatuses");
  },
};

export const getters = {
  backgroundColors: (state) => state.backgroundColors,
  countries: (state) => state.countries,
  factorTypes: (state) => state.factorTypes,
  manufacturers: (state) => state.manufacturers,
  months: (state) => state.months,
  orderFulfillmentStatuses: (state) => state.orderFulfillmentStatuses,
  products: (state) => state.products,
  quarters: (state) => state.quarters,
  regions: (state) => state.regions,
  warehouses: (state) => state.warehouses,
};
